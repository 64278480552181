import * as Constants from "./config.js";
function randomIntFromInterval(min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

var randomBool = (function() {
  var a = new Uint8Array(1);
  return function() {
    crypto.getRandomValues(a);
    return a[0] > 127;
  };
})();



export const STRIPE=false
export const PAYPAL=true
export const SQUARE=false
export const ZEN=false
export const CRYPTO=false


export const EMERGENCY_COST=50
let result=""
if (Constants.SHOW_PRICE=="random")
  result=randomBool()
else
  result=Constants.SHOW_PRICE

//Override
result=true
//result=randomBool()

export const REVEAL_PRICE=result
//export const REVEAL_PRICE=false
//export const REVEAL_PRICE=randomBool()
//export const REVEAL_PRICE=false
export const SHOW_COST=false
export const SHOW_COST_PAYMENT_ONLY=false
export const SHOW_TBD=true
export const SITE_KEY=process.env.REACT_APP_SITE_KEY
//export const SERVER="GOVISA.AI"
//export const SERVER_URL="govisa.ai"
var a=window.location.hostname.split(".")[0]
var b=window.location.hostname.split(".")[1]
var c=window.location.hostname.split(".")[2]
var domain=""
if (!c)
  domain=a+"."+b
else
  domain=b+"."+c



export const SERVER="GOVIS.AI"
export const SERVER_URL=domain
export const GUIDED=[]


export const ZEN_TEST_PORT=8943
export const ZEN_PORT=2083


var SQUARE1_COMPANY="DAVEST HOLDINGS LLC"
var SQUARE1_APPLICATION_ID=process.env.REACT_APP_SQUARE1_APPLICATION_ID
var SQUARE1_PORT=8543
var SQUARE1_LOCATION_ID="LENRNV8FZC7XV"

var SQUARE2_COMPANY="HADAR TECH LLC"
var SQUARE2_APPLICATION_ID=process.env.REACT_APP_SQUARE2_APPLICATION_ID
var SQUARE2_PORT=8544
var SQUARE2_LOCATION_ID="LJZA5A0FDV7E7"

var SQUARE3_COMPANY="TZEDEK TECH LLC"
var SQUARE3_APPLICATION_ID=process.env.REACT_APP_SQUARE3_APPLICATION_ID
var SQUARE3_PORT=8545
var SQUARE3_LOCATION_ID="LZKPF963B9337"

var SQUARE4_COMPANY="KOSHERWHERE LLC"
var SQUARE4_APPLICATION_ID=process.env.REACT_APP_SQUARE4_APPLICATION_ID
var SQUARE4_PORT=8546
var SQUARE4_LOCATION_ID="LJJK1CEX5GGTN"


export const SQUARE_TEST_PORT=8743




//ZAATARIUS
var STRIPE1=process.env.REACT_APP_STRIPE1
var STRIPE1_PORT=8443

var STRIPE2=process.env.REACT_APP_STRIPE2
var STRIPE2_PORT=8444


//KOSHERWHERE
var PAYPAL1=process.env.REACT_APP_PAYPAL1
//GOVIS PayPal
var PAYPAL2=process.env.REACT_APP_PAYPAL2

let which_square=""
let which_paypal=""
let which_stripe=""
if (Constants.WHICH_STRIPE=="random")
  which_stripe=randomIntFromInterval(1, 2);
else
  which_stripe=Constants.WHICH_STRIPE


which_square=randomIntFromInterval(2, 3);
console.log(which_square)
which_square=3;


var STRIPE_CLIENT_=""
var SQUARE_CLIENT_=""
var PAYPAL_CLIENT_=""
var STRIPE_PORT_=""
var SERVER_IP_=""
var SQUARE_APPLICATION_ID_=""
var SQUARE_PORT_ =""
var SQUARE_LOCATION_ID_ =""
var SQUARE_COMPANY_ =""



///OVERRIDE////
//which_stripe=randomIntFromInterval(1, 2);
which_stripe=2
which_paypal=1
//alert(which_square  )
//which_square=3

if (which_stripe==1)
{
   STRIPE_CLIENT_=STRIPE1
   STRIPE_PORT_=STRIPE1_PORT
   SERVER_IP_=SERVER_URL
   SQUARE_COMPANY_=SQUARE1_COMPANY

}
else if (which_stripe==2)
{
   STRIPE_CLIENT_=STRIPE2
   STRIPE_PORT_=STRIPE2_PORT
   SERVER_IP_="govisa.ai"
   SQUARE_COMPANY_=SQUARE1_COMPANY

}


if (which_square==1)
{
   SQUARE_APPLICATION_ID_=SQUARE1_APPLICATION_ID
   SQUARE_PORT_=SQUARE1_PORT
   SQUARE_LOCATION_ID_=SQUARE1_LOCATION_ID
   SQUARE_COMPANY_=SQUARE1_COMPANY



}
else if (which_square==2)
{
   SQUARE_APPLICATION_ID_=SQUARE2_APPLICATION_ID
   SQUARE_PORT_=SQUARE2_PORT
   SQUARE_LOCATION_ID_=SQUARE2_LOCATION_ID
   SQUARE_COMPANY_=SQUARE2_COMPANY

}
else if (which_square==3)
{
   SQUARE_APPLICATION_ID_=SQUARE3_APPLICATION_ID
   SQUARE_PORT_=SQUARE3_PORT
   SQUARE_LOCATION_ID_=SQUARE3_LOCATION_ID
   SQUARE_COMPANY_=SQUARE3_COMPANY

}
else if (which_square==4)
{
   SQUARE_APPLICATION_ID_=SQUARE4_APPLICATION_ID
   SQUARE_PORT_=SQUARE4_PORT
   SQUARE_LOCATION_ID_=SQUARE4_LOCATION_ID
   SQUARE_COMPANY_=SQUARE4_COMPANY

}


if (!SQUARE)
      SQUARE_COMPANY_="KOSHEWHERE LLC"




if (which_paypal==1)
{
   PAYPAL_CLIENT_=PAYPAL1
   //SQUARE_COMPANY_=SQUARE4_COMPANY

}
else if (which_paypal==2)
{
   PAYPAL_CLIENT_=PAYPAL2
   //SQUARE_COMPANY_=SQUARE1_COMPANY

}

//alert(which_stripe)
export const STRIPE_CLIENT=STRIPE_CLIENT_
export const PAYPAL_CLIENT=PAYPAL_CLIENT_
export const STRIPE_PORT=STRIPE_PORT_
export const SERVER_IP=SERVER_IP_
export const SQUARE_APPLICATION_ID=SQUARE_APPLICATION_ID_
export const SQUARE_PORT=SQUARE_PORT_
export const SQUARE_LOCATION_ID=SQUARE_LOCATION_ID_
export const COMPANY=SQUARE_COMPANY_


export const STRIPE_TEST_KEY=process.env.REACT_APP_STRIPE_TEST_KEY
export const PAYPAL_TEST_KEY=process.env.REACT_APP_PAYPAL_TEST_KEY
export const SQUARE_TEST_KEY=process.env.REACT_APP_SQUARE_TEST_KEY


export const TEST_ALLOW_IP="2a01:cb00:9a8:d000"
//2a01:cb00:f:7500

export const PAYPAL_PAYMENT="Paypal/Credit Card"
export const STRIPE_PAYMENT="Credit/Debit Card-ST"
export const SQUARE_PAYMENT="Credit/Debit Card-SQ"
export const ZEN_PAYMENT="Credit/Debit Card-ZN"
export const CRYPTO_PAYMENT="Cryptocurrency"
export const DEFAULT_PAYMENT=PAYPAL_PAYMENT


export const ENABLE_DELIVERY_ISSUE=false
//export const SERVER_URL="aitrvl.com"
//export const SERVER="AI.TRVL"
